import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import { useTranslation, Trans } from 'react-i18next';
import {
	Button,
	Stack,
	Grid,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	List,
	ListItem,
	Typography,
	IconButton,
	CircularProgress,
	ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Add,
	Save,
	NotStarted,
	StopCircle,
	AddCircle,
} from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.min.css';

import SimpleChooseDialog from '../../components/SimpleChooseDialog';


function WorkDetailsPage() {
	let params = useParams();
	let navigate = useNavigate();
	let defaultItem = { name: '', location: { name: '' } };

	const worklineid = params.id;
	const [data, setData] = useState(defaultItem);
	const [loading, setLoading] = useState(false);
	const [LoadingExtraEquip, setLoadingExtraEquip] = useState(false);
	const [saving, setSaving] = useState(false);
	const [tripCount, setTripCount] = useState(0);
	const [isStarted, setHasStarted] = useState(false);
	const [hasEnded, setHasEnded] = useState(false);
	const [worklinecomment, setWorklinecomment] = useState('');
	const [additionalEquip, setAdditionalEquip] = useState('');
	const [additionalEquipOnWorkLine, setAdditionalEquipOnWorkLine] = useState(
		{},
	);
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [tripProducts, setTripProducts] = React.useState('');
	const [tripCurrentProduct, setCurrentTripProduct] = React.useState('');

	const { user, defaultEquipment } = useSelector((state) => state.employee);
	const { t } = useTranslation();

	function handleChangeProduct(event) {
		setCurrentTripProduct(event.target.value);
	}

	//Loading of init data
	useEffect(() => {
		getWorkLineDetails(params.id);

		axios.get('/api/work/gettripcount/' + params.id).then((item) => {
			setTripCount(item.data + 0);
		});

		getExtraEquipmentLines(params.id);
	}, []);

	///Get the workline details
	function getWorkLineDetails(id) {
		setLoading(true);
		axios.get('/api/work/workline/' + id).then((item) => {
			setData(item.data);
			setHasStarted(item.data.isStarted);
			setHasEnded(item.data.isFinished);
			setWorklinecomment(item.data.employeeComment);
			setLoading(false);
			if (item?.data?.product) {
				setCurrentTripProduct(item.data.product.id);
			}
			//If it's akstur we fetch available products
			getAvailableTripProducts();
		});
	}

	//Get which products can be seen in trips
	function getAvailableTripProducts() {
		setLoading(true);
		axios
			.get('/api/work/GetAvailableProductsInTrips/' + user.id)
			.then((lines) => {
				setTripProducts(lines.data);
				setLoading(false);
			});
	}

	///Get the extra equipment lines if any on this line
	function getExtraEquipmentLines(id) {
		setLoadingExtraEquip(true);
		axios
			.get('/api/work/getworklineextraequipmentlines/' + id)
			.then((item) => {
				setAdditionalEquipOnWorkLine(item.data);
				setLoadingExtraEquip(false);
			});
	}

	//Delete extra equipment
	function delExtraEquipmentLine(item) {
		setLoadingExtraEquip(true);
		axios.get('/api/work/delWorkLineEquipLine/' + item.id).then((item) => {
			getExtraEquipmentLines(worklineid);
			setLoadingExtraEquip(false);
		});
	}

	// Call the api to create the actual line
	function handleStart(position = null) {
		const coords =
			position != null
				? {
						lat: position.coords.latitude,
						lon: position.coords.longitude,
				  }
				: { lat: 0, lon: 0 };
		axios
			.post('/api/work/startworkline', {
				EId: user.id,
				WId: worklineid,
				EqId: data.equipment == null ? defaultEquipment.id : '', // equipment of logged in user, if no equipment has been set on line
				Location: {
					Latitude: coords.lat,
					Longitude: coords.lon,
				},
			})
			.then((res) => {
				setLoading(false);
				setHasStarted(true);
				toast.success(user.name + ' hóf störf við þessa verklínu', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				//Get the info again?
				getWorkLineDetails(worklineid);
			})
			.catch((err) => {
				setLoading(false);
				toast.error('Ekki tókst að hefja verkið ' + err);
				// handle error
			});
	}

	// Called when útskráning á verklínu
	function handleEnd(position = null) {
		const coords =
			position != null
				? {
						lat: position.coords.latitude,
						lon: position.coords.longitude,
				  }
				: { lat: 0, lon: 0 };
		axios
			.post('/api/work/endworkline', {
				EmployeeId: user.id,
				EquipmentId: '',
				WorklineId: worklineid,
				Location: {
					Latitude: coords.lat,
					Longitude: coords.lon,
				},
			})
			.then((res) => {
				setLoading(false);
				setHasStarted(true);
				setHasEnded(true);
				toast.success(
					user.name + " kláraði verklínu '" + data.name + "'",
					{
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					},
				);
				//Get the info again?
				//getWorkLineDetails(worklineid);
				navigate('/work'); //Goto worklines after end work
			})
			.catch((err) => {
				setLoading(false);
				toast.error('Ekki tókst að enda verkið ' + err);
				// handle error
			});
	}

	//Click on hefja verk
	function beginWorkClicked() {
		setLoading(true);

		//Get location before checkin
		navigator.geolocation.getCurrentPosition(
			handleStart,
			(error) => {
				//const errorMessages = {
				//	1: 'This website does not have permission to use the location.',
				//	2: 'The current position is unavailable.',
				//	3: 'The current position could not be determined within the specified timeout period.',
				//};
				//toast.error(errorMessages[error.code] || 'Error getting current location');
				handleStart();
			},
			{ enableHighAccuracy: true, timeout: 60000, maximumAge: 0 },
		);
	}

	function saveUserComment() {
		if (worklinecomment == null) {
			return;
		}

		setSaving(true);

		axios
			.post('/api/work/savecomment', {
				WId: worklineid,
				Comment: worklinecomment,
			})
			.then((res) => {
				setSaving(false);
				toast.success('Athugasemd vistuð', {
					position: 'bottom-left',
					autoClose: 6000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			})
			.catch((err) => {
				setLoading(false);
				toast.error('Ekki tókst að vista athugasemd ' + err);
				// handle error
			});
	}

	function endWorkLineClick() {
		setLoading(true);

		//Get location before checkin
		navigator.geolocation.getCurrentPosition(
			handleEnd,
			(error) => {
				//const errorMessages = {
				//	1: 'This website does not have permission to use the location.',
				//	2: 'The current position is unavailable.',
				//	3: 'The current position could not be determined within the specified timeout period.',
				//};
				//toast.error(errorMessages[error.code] || 'Error getting current location');
				handleEnd();
			},
			{ enableHighAccuracy: true, timeout: 60000, maximumAge: 0 }
		);
	}
	//Add new trip
	function addTripClick() {
		//CreateTripline
		setLoading(true);
		axios
			.post('/api/work/createtripline', {
				EId: user.id,
				WId: worklineid,
				From:
					data.from != null
						? data.from.id
						: data.location != null
						? data.location.id
						: '', //To and from
				To: data.to != null ? data.to.id : '',
				TripCount: 'Ferð ' + (tripCount + 1), //Purposly done like this
				Product: tripCurrentProduct,
			})
			.then((res) => {
				setLoading(false);
				//currentUser.name
				toast.success("+1 ferð á '" + data.name + "'", {
					position: 'top-center',
					autoClose: 6000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				//Increment trip counter
				setTripCount(tripCount + 1);
				navigate('/work'); //Fara aftur í verklínur eftir plús ferð
			})
			.catch((err) => {
				setLoading(false);
				toast.error('Ekki tókst að stofna ferð ' + err);
				// handle error
			});
	}

	function handleError(error) {
		var message = '';
		switch (error.code) {
			case error.PERMISSION_DENIED:
				message =
					'This website does not have permission to use the location.';
				break;
			case error.POSITION_UNAVAILABLE:
				message = 'The current position is unavailable.';
				break;
			case error.TIMEOUT:
				message =
					'The current position could not be determined within the specified timeout period. Try again.';
				break;
			default:
				message = 'Error getting current location';
				break;
		}

		toast.error(message);
	}

	function handleClickOpen() {
		setDialogOpen(true);
	}
	function handleCloseAdditionalEquipment(value, hours) {
		setDialogOpen(false);
		if (value !== '' && value != null && hours > 0) {
			setAdditionalEquip(value);
			//Create additional equipment line
			addExtraEquipmentClick(value, hours);
		}
	}

	function addExtraEquipmentClick(value, hours) {
		//Create extra equipment
		setLoading(true);
		axios
			.post('/api/work/addextraequipment', {
				EId: user.id,
				EqId: value.id,
				WId: worklineid,
				Hours: hours,
			})
			.then((res) => {
				setLoading(false);
				//currentUser.name
				toast.success('Bætti aukabúnaði við verklínuna', {
					position: 'bottom-center',
					autoClose: 6000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				getExtraEquipmentLines(worklineid);
			})
			.catch((err) => {
				setLoading(false);
				toast.error(
					'Ekki tókst að setja aukabúnað á verklínuna ' + err,
				);
				// handle error
			});
	}

	// The looks of this control render
	return (
		<>
			{loading ? (
				<Container fluid>
					<Row>
						<Col>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: '100vh',
								}}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: '100vh',
									}}
								>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center"
									>
										<Grid item xs>
											<h3>
												<Trans i18nKey="home.loading">
													Hleð gögn
												</Trans>
											</h3>
										</Grid>
										<Grid item xs={6}>
											<CircularProgress />
										</Grid>
									</Grid>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			) : (
				<Container fluid>
					<Row className="mt-2">
						<Col>
							<Typography variant="h3" sx={{ fontWeight: 'bold' }}>
								{hasEnded
									? '🟢 '
									: isStarted && !hasEnded
									? '🔵 '
									: !isStarted && !hasEnded
									? '🔴 '
									: ''}
								{data.shortName}
							</Typography>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<h4>
								{data.from != null
									? t('work.from') + data.from.name
									: t('work.location') +
									  (data.location != null
											? data.location.name
											: '')}
							</h4>
						</Col>
						<Col>
							<h4>
								{data.to != null
									? t('work.to') + data.to.name
									: ''}
							</h4>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							{data.workLineType &&
							data.workLineType.name.startsWith('Akstur') ? (
								''
							) : (
								<h4>
									{data.workLineType != null
										? t('work.type') +
										  data.workLineType.name
										: ''}
								</h4>
							)}
							<br />
							{data.workLineType &&
							data.workLineType.name.startsWith('Akstur') ? (
								<FormControl
									size="small"
									margin="none"
									required={true}
									fullWidth
								>
									<InputLabel id="productlabel">
										{t('work.product')}
									</InputLabel>
									<Select
										labelId="productlabel"
										id="product-select"
										value={tripCurrentProduct}
										label={t('work.product')}
										onChange={handleChangeProduct}
									>
										{tripProducts.length > 0
											? tripProducts.map(
													(proj, index) => (
													<MenuItem
															key={proj.id}
															value={proj.id}
															name={proj.name}
														>
															{proj.name}
														</MenuItem>
													),
											  )
											: null}
									</Select>
								</FormControl>
							) : (
								<h4>
									{data.product != null
										? t('work.product') + data.product.name
										: ''}
								</h4>
							)}
						</Col>
						<Col>
							<h4>
								{data.equipment != null
									? t('work.equip') +
									  data.equipment.displayName
									: t('work.yourequip') +
									  defaultEquipment.displayName}
							</h4>
							<br />
							<h4>
								{data.quantity != null
									? t('work.count') + data.quantity
									: ''}
							</h4>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<h6>
								<Trans i18nKey="work.description">Lýsing</Trans>
							</h6>
							<div style={{ fontSize: '1.6em' }}>
								{parse(
									data.description != null
										? data.description
										: '',
								)}
							</div>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							{!isStarted ? (
								<Button
									startIcon={<NotStarted size="large" />}
									variant="contained"
									size="large"
									style={{ width: 180, height: 70 }}
									onClick={beginWorkClicked}
									disabled={isStarted}
								>
									<Trans i18nKey="work.start">
										Hefja verk
									</Trans>
								</Button>
							) : (
								t('work.workstarted') + data.startTimePretty
							)}
						</Col>
						<Col>
							{!hasEnded ? (
								<Button
									startIcon={<StopCircle size="large" />}
									variant="contained"
									size="large"
									style={{ width: 180, height: 70 }}
									onClick={endWorkLineClick}
									disabled={hasEnded || !isStarted}
								>
									<Trans i18nKey="work.close">
										Loka verki
									</Trans>
								</Button>
							) : (
								t('work.workended') + data.endTimePretty
							)}
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							{data.workLineType != null &&
							data.workLineType.name.startsWith('Akstur') ? (
								<Button
									size="large"
									startIcon={<AddCircle size="large" />}
									variant="contained"
									color="warning"
									style={{ width: 180, height: 70 }}
									onClick={addTripClick}
									disabled={!isStarted || hasEnded}
								>
									{'+1 ' +
										t('work.trip') +
										' (' +
										tripCount +
										')'}
								</Button>
							) : (
								''
							)}
						</Col>
						<Col></Col>
					</Row>
					<Row className="mt-4">
						<Col>
							<Form.Group
								className="mb-3"
								controlId="exampleForm.ControlTextarea1"
							>
								<Typography
									sx={{ mt: 4, mb: 2 }}
									variant="h6"
									component="div"
								>
									<Trans i18nKey="work.empnote">
										Athugasemd starfsmanns
									</Trans>
								</Typography>
								<Form.Control
									as="textarea"
									rows={3}
									value={worklinecomment ?? ''}
									onChange={(e) =>
										setWorklinecomment(e.target.value)
									}
									onBlur={saveUserComment}
								/>
							</Form.Group>
							<Button
								size="medium"
								endIcon={<Save />}
								variant="contained"
								style={{
									width: 200,
									height: 50,
									backgroundColor: '#5f8fc6',
								}}
								onClick={saveUserComment}
								disabled={saving}
							>
								{' '}
								<Trans i18nKey="work.savenote">
									Vista athugasemd
								</Trans>
							</Button>
						</Col>
						{data.workLineType != null &&
						!data.workLineType.name.startsWith('Akstur') ? (
							<Col>
								<Typography
									sx={{ mt: 4, mb: 2 }}
									variant="h6"
									component="div"
								>
									<Trans i18nKey="work.extra">
										Aukabúnaður
									</Trans>
								</Typography>
								{LoadingExtraEquip ? (
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '15vh',
										}}
									>
										<Stack spacing={2}>
											<h5>Eyði...</h5>
											<CircularProgress />
										</Stack>
									</div>
								) : (
									<List dense>
										{additionalEquipOnWorkLine.length > 0
											? additionalEquipOnWorkLine.map(
													(item, index) => (
														<ListItem
															secondaryAction={
																<IconButton
																	edge="end"
																	aria-label="delete"
																	onClick={() =>
																		delExtraEquipmentLine(
																			item,
																		)
																	}
																	key={
																		item.id
																	}
																>
																	<DeleteIcon />
																</IconButton>
															}
														>
															{item
																.workLineEquipment
																.image !=
															null ? (
																<img
																	src={
																		'data:image/png;base64, ' +
																		(item
																			.workLineEquipment
																			.image !=
																		null
																			? item
																					.workLineEquipment
																					.image
																			: '')
																	}
																	width={50}
																	height={50}
																	alt="Icon"
																/>
															) : (
																''
															)}
															<ListItemText
																primary={
																	item.name
																}
															/>
														</ListItem>
													),
											  )
											: null}
									</List>
								)}
								<Button
									size="medium"
									startIcon={<Add />}
									variant="contained"
									style={{
										width: 180,
										height: 50,
										backgroundColor: '#5f8fc6',
									}}
									onClick={handleClickOpen}
								>
									<Trans i18nKey="work.extra">
										Aukabúnaður
									</Trans>
								</Button>
							</Col>
						) : (
							''
						)}
					</Row>
					<Row className="mt-4">
						<Col>
							<SimpleChooseDialog
								selectedValue={additionalEquip}
								open={dialogOpen}
								onClose={handleCloseAdditionalEquipment}
							/>
						</Col>
					</Row>
				</Container>
			)}
		</>
	);
}

export default WorkDetailsPage;
