import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/api';
import { EXCAVATOR } from '../constants';
import { hasPrivilege } from '../utils/privilege.utils';

const initialState = {
	defaultEquipment: {
		id: '',
		displayName: '',
		name: '',
		odometer: 0,
		odometerUnit: 'km', // hours
		categoryName: '',
		type: '',
		subType: '',
	},
	user: {
		id: null,
		name: null,
		language: null,
		hasBirtday: false,
		privileges: [],
	},
	hasStimpilklukka: false,
	hasVerklinur: false,
	hasDriver: false,
	timeClock: {
		isLoading: false,
		isActive: false,
		timeClockedIn: null,
		timeClockedOut: null,
		durationMinutes: null,
		timeRecordId: null,
		equipmentClockedIn: {
			id: '',
			displayName: '',
			name: '',
			odometer: 0,
			odometerUnit: 'km', // hours
			categoryName: '',
			type: '',
			subType: '',
		},
	},
	isTimeRecordDialogOpen: false,
	selectedTimeRecordId: null,
	isOdometerDialogOpen: false,
};

export const employeeSlice = createSlice({
	name: 'employee',
	initialState,
	reducers: {
		// Odometer Dialog
		openOdometerDialog: (state, action) => {
			console.log("🔔 Opening odometer dialog")
			if (!state.defaultEquipment?.id) return;
			state.isOdometerDialogOpen = true;
		},
		closeOdometerDialog: (state) => {
			console.log("🔔 Closing odometer dialog")
			state.isOdometerDialogOpen = false;
		},
		// Time Record Dialog
		openTimeRecordDialog: (state, action) => {
			console.log("🔔 Opening time record dialog")
			state.isTimeRecordDialogOpen = true;
			state.selectedTimeRecordId = action.payload;
		},
		closeTimeRecordDialog: (state) => {
			console.log("🔔 Closing time record dialog")
			state.isTimeRecordDialogOpen = false;
			state.selectedTimeRecordId = null;
		},
		// Time Clock
		setTimeClockIsLoading: (state, action) => {
			console.log(`🔔 Setting time clock loading to ${action.payload}`);
			state.timeClock.isLoading = action.payload;
        },
		setTimeClockedIn: (state, action) => {
			console.log("🔔 Setting time clocked in")
			state.timeClock.timeClockedIn = action.payload;
			state.timeClock.isActive = true;
		},
		setTimeClockedOut: (state, action) => {
			console.log("🔔 Setting time clocked out")
			state.timeClock.timeClockedOut = action.payload;
			state.timeClock.isActive = false;
		},
		// Equipment
		setEquipmentOdometer: (state, action) => {
			const odometer = action.payload;
			console.log(`🔔 Setting equipment odometer to ${odometer}`);
			state.defaultEquipment.odometer = odometer;
		},
		// User
		setUser: (state, action) => {
			state.user = action.payload;
			state.hasStimpilklukka = hasPrivilege(action.payload, 'stimpilklukka');
			state.hasVerklinur = hasPrivilege(action.payload, 'verklinur');
			state.hasDriver = hasPrivilege(action.payload, 'bilstjori');
		},
		clearUser: (state) => {
			state.user = { ...initialState.user };
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			api.endpoints.getLatestWorkline.matchPending,
			(state, action) => {
				console.log("🔃 Fetching latest workline ...");
			},
		);
		builder.addMatcher(
			api.endpoints.getLatestWorkline.matchFulfilled,
			(state, action) => {
				const workline = action.payload;

				console.log("Latest WorkLine:")
				console.log(workline);

				// set default equipment odometer as latest km out
				if (workline?.kMout) {
					console.log("Setting equipment odometer as latest KM out");
					const clockedOutKM = Number(workline.kMout);

					console.log("Setting equipment odometer as latest KM out");
					state.defaultEquipment.odometer = clockedOutKM;
				}
				// set default equipment odometer as latest km in
				else if (workline?.kMin) {
					const clockedInKM = Number(workline.kMin);

					console.log("Setting equipment odometer as latest KM in");
					state.defaultEquipment.odometer = clockedInKM;
				}
			},
		);
		builder.addMatcher(
			api.endpoints.getClockInStatus.matchPending,
			(state, action) => {
				console.log("🔃 Fetching clock in status ...");
				state.timeClock.isLoading = true;
			},
		);
		builder.addMatcher(
			api.endpoints.getClockInStatus.matchFulfilled,
			(state, action) => {
				state.timeClock.isLoading = false;

				const timeRecord = action.payload;
				if (!timeRecord) {
					state.timeClock.isActive = false;
					return;
				}
				state.timeClock.isActive = timeRecord?.start && !timeRecord?.end;
				state.timeClock.timeRecordId = timeRecord?.id || null;
				state.timeClock.timeClockedIn = timeRecord?.start || null;
				state.timeClock.timeClockedOut = timeRecord?.end || null;
				state.timeClock.durationMinutes =
					timeRecord?.durationMinutes || null;
			},
		);
		builder.addMatcher(
			api.endpoints.getDefaultEquipment.matchPending,
			(state, action) => {
				console.log("🔃 Fetching default equipment ...");
				state.timeClock.isLoading = true;
			},
		);
		builder.addMatcher(
			api.endpoints.getDefaultEquipment.matchFulfilled,
			(state, action) => {
				state.timeClock.isLoading = false;
				const [equipment] = action.payload;

				if (equipment) {
					state.defaultEquipment.id = equipment?.id || '';
					state.defaultEquipment.displayName = equipment?.displayName || '';
					state.defaultEquipment.name = equipment?.name || '';
					state.defaultEquipment.odometer = equipment?.odometer || '';
					state.defaultEquipment.type = equipment?.type || '';
					state.defaultEquipment.subtype = equipment?.subType || '';

					state.defaultEquipment.odometerUnit =
						(equipment?.equipmentType?.category === EXCAVATOR
							? 'hours'
							: 'km') || '';
					state.defaultEquipment.categoryName = equipment?.equipmentType?.name || '';
				} else {
					state.defaultEquipment = {
						id: '',
						displayName: '',
						name: '',
						odometer: '',
						type: '',
						subType: '',
						odometerUnit: '',
						categoryName: '',
					};
				}
			},
		);
	},
});

export const employeeActions = employeeSlice.actions;
