import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import {
    useGetProjectWorklineTypesQuery,
    useGetOpenProjectsQuery,
    useGetAvailableProductsQuery,
    useGetAvailableWorksitesQuery,
    useGetAllEquipmentQuery,
    useGetWorklineTypesQuery
} from '../../services/api';
import { skipToken } from '@reduxjs/toolkit/query/react';

const fadeVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto' }
};


const FormRow = ({ children, show = true, fullWidth = false }) => (
    <motion.div
        initial="hidden"
        animate={show ? "visible" : "hidden"}
        variants={fadeVariants}
        transition={{ duration: 0.3 }}
    >
        <Row className={`${show ? "mt-3" : "mt-0"} ${fullWidth ? 'w-full' : ''}`}>
            <Col>{children}</Col>
        </Row>
    </motion.div>
);

const SelectField = ({
    label,
    value,
    onChange,
    options,
    loading,
    required = true,
    disabled = false,
    formatDisplay = null
}) => {
    return (
        <FormControl required={required} fullWidth disabled={disabled}>
            <InputLabel shrink id={`${label}-label`}>{label}</InputLabel>
            {loading ? (
                <Skeleton variant="rectangular" className="transition-all duration-300" height={48} />
            ) : (
                <Select
                    native
                    value={value || ''}
                    labelId={`${label}-label`}
                    notched
                    label={label}
                    onChange={onChange}
                    className="transition-all duration-300"
                    inputProps={{
                        style: { backgroundColor: 'white' },
                    }}
                >
                    <option value="" disabled></option>
                    {options?.length > 0 ? options.map((option) => (
                        <option key={option.id} value={option.id}>
                            {formatDisplay ? formatDisplay(option) : option.displayName || option.name}
                        </option>
                    )) : null}
                </Select>
            )}
        </FormControl>
    );
};

function NewWorkLine() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const AKSTUR = '1a63da31-6bb0-ec11-9840-000d3abf79e7';
    const [loading, setLoading] = useState(false);
    const { user, defaultEquipment } = useSelector((state) => state.employee);

    const [spinnerMsg, setSpinnerMsg] = useState('Sæki gögn');
    const [project, setProject] = useState('');
    const [product, setProduct] = useState('');
    const [workType, setWorkType] = useState('');
    const [equipment, setEquipment] = useState('');
    const [worksite, setWorkSite] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [showToFrom, setShowToFrom] = useState(false);
    const [showWorkSite, setShowWorkSite] = useState(false);
    const [equipmentList, setEquipmentList] = useState([]);

    // Queries
    const { data: projects = [], isLoading: isLoadingProjects } = useGetOpenProjectsQuery(user.id);
    const { data: products = [], isLoading: isLoadingProducts } = useGetAvailableProductsQuery(user.id);
    const { data: workSites = [], isLoading: isLoadingWorkSites } = useGetAvailableWorksitesQuery(user.id);
    const { data: availableEquipment = [], isLoading: isLoadingEquipment } = useGetAllEquipmentQuery(user.id);

    // Use both queries for workline types
    const shouldFetchProjectTypes = project && project !== '';
    const {
        data: projectWorklineTypes = [],
        isLoading: isLoadingProjectWorklineTypes
    } = useGetProjectWorklineTypesQuery(shouldFetchProjectTypes ? project : skipToken);

    const {
        data: generalWorklineTypes = [],
        isLoading: isLoadingGeneralWorklineTypes
    } = useGetWorklineTypesQuery(user.id);

    // Combine workline types, preferring project-specific ones
    const worklinetypes = projectWorklineTypes.length > 0 ? projectWorklineTypes : generalWorklineTypes;
    const isLoadingWorklineTypes = isLoadingProjectWorklineTypes || isLoadingGeneralWorklineTypes;

    // Automatically select the worksite as the project main worksite
    useEffect(() => {
        if (project) {
            const selectedProject = projects.find(p => p.id === project);
            if (selectedProject) {
                setWorkSite(selectedProject.mainWorkSite);
            }
        }
    }, [project, projects]);

    // Add default equipment to equipment list (if not included) & select default equipment
    useEffect(() => {
        if (availableEquipment.length > 0 && equipmentList.length === 0) {
            if (defaultEquipment?.id) {
                const isDefaultIncluded = availableEquipment.find(e => e.id === defaultEquipment.id);

                if (isDefaultIncluded) {
                    // Default already in available equipment
                    setEquipmentList(availableEquipment);
                } else {
                    // Add default equipment to list
                    setEquipmentList([...availableEquipment, { id: defaultEquipment.id, displayName: defaultEquipment.displayName }]);
                }

                // Select default equipment
                if (!equipment) {
                    setEquipment(defaultEquipment.id);
                }
            } else {
                setEquipmentList(availableEquipment);
                const verkamadur = availableEquipment.find(e => e.displayName === "Verkamaður");
                setEquipment(verkamadur.id);
            }
        }
    }, [availableEquipment, equipmentList, defaultEquipment, equipment])

    const handleChangeTypeObj = (event) => {
        setWorkType(event.target.value);
        setShowToFrom(event.target.value === AKSTUR);
        setShowWorkSite(event.target.value !== AKSTUR && event.target.value !== '');
    };

    const validateInputFields = () => {
        const validations = [
            { condition: project === '', message: 'Nauðsynlegt er að fylla út verkefni' },
            { condition: workType === '', message: 'Nauðsynlegt er að fylla út tegund verks (Tegund)' },
            { condition: product === '' && workType === AKSTUR, message: 'Nauðsynlegt er að fylla út hvað er verið að flytja (Vara)' },
            { condition: equipment === '', message: 'Nauðsynlegt er að fylla út tæki sem nota skal (Tæki)' },
            { condition: worksite === '' && workType !== AKSTUR, message: 'Nauðsynlegt er að fylla út verkstað' },
            { condition: from === '' && workType === AKSTUR, message: 'Nauðsynlegt er að fylla út hvaðan keyra skal (Frá)' },
            { condition: to === '' && workType === AKSTUR, message: 'Nauðsynlegt er að fylla út hvert keyra skal (Til)' }
        ];

        const failedValidation = validations.find(v => v.condition);
        if (failedValidation) {
            toast.error(failedValidation.message);
            return false;
        }
        return true;
    };

    const handleCreate = async (position = null) => {
        try {
            const coords = position ? {
                lat: position.coords.latitude,
                lon: position.coords.longitude,
            } : { lat: 0, lon: 0 };

            const response = await axios.post('/api/work/createworkline', {
                EmployeeId: user.id,
                ProjectId: project,
                EquipmentId: equipment,
                KMin: '',
                WorklineTypeId: workType,
                WorksiteId: worksite,
                FromId: from,
                ToId: to,
                ProductId: product,
                Location: {
                    Latitude: coords.lat,
                    Longitude: coords.lon,
                },
            });

            toast.success('Verklína stofnuð');
            navigate('/work/details/' + response.data, { replace: true });
        } catch (error) {
            toast.error('Ekki tókst að stofna línu: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const createWorkLineClick = () => {
        if (!validateInputFields()) return;

        setSpinnerMsg('Stofna verklínu');
        setLoading(true);

        navigator.geolocation.getCurrentPosition(
            handleCreate,
            (error) => {
                //const errorMessages = {
                //    1: 'This website does not have permission to use the location.',
                //    2: 'The current position is unavailable.',
                //    3: 'The current position could not be determined within the specified timeout period.',
                //};
                //toast.error(errorMessages[error.code] || 'Error getting current location');
                handleCreate();
            },
            { enableHighAccuracy: true, timeout: 60000, maximumAge: 0 }
        );
    };

    if (loading) {
        return (
            <Container fluid className="h-screen flex items-center justify-center">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-center"
                >
                    <h3 className="mb-4">{spinnerMsg}</h3>
                    <CircularProgress />
                </motion.div>
            </Container>
        );
    }

    return (
        <Container fluid className="max-w-2xl mx-auto">
            <FormRow>
                <SelectField
                    label={t('newline.project')}
                    value={project}
                    onChange={(e) => setProject(e.target.value)}
                    options={projects}
                    loading={isLoadingProjects}
                />
            </FormRow>

            <FormRow>
                <SelectField
                    label={t('newline.ttype')}
                    value={workType}
                    onChange={handleChangeTypeObj}
                    options={worklinetypes}
                    loading={isLoadingWorklineTypes}
                />
            </FormRow>

            <FormRow show={showToFrom}>
                <SelectField
                    label={t('newline.product')}
                    value={product}
                    onChange={(e) => setProduct(e.target.value)}
                    options={products}
                    loading={isLoadingProducts}
                />
            </FormRow>

            <FormRow>
                <SelectField
                    label={t('newline.equip')}
                    value={equipment}
                    onChange={(e) => setEquipment(e.target.value)}
                    options={equipmentList}
                    loading={isLoadingEquipment}
                    formatDisplay={(option) => `${option.displayName}`}
                />
            </FormRow>

            <FormRow show={showWorkSite}>
                <SelectField
                    label={t('newline.location')}
                    value={worksite}
                    onChange={(e) => setWorkSite(e.target.value)}
                    options={workSites}
                    loading={isLoadingWorkSites}
                />
            </FormRow>

            <FormRow show={showToFrom}>
                <SelectField
                    label={t('newline.from')}
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    options={workSites}
                    loading={isLoadingWorkSites}
                />
            </FormRow>

            <FormRow show={showToFrom}>
                <SelectField
                    label={t('newline.to')}
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    options={workSites}
                    loading={isLoadingWorkSites}
                />
            </FormRow>

            <FormRow fullWidth={true}>
                <Button
                    variant="success"
                    size="lg"
                    onClick={createWorkLineClick}
                    disabled={loading}
                >
                    <Trans i18nKey="newline.create">Stofna verk</Trans>
                </Button>
            </FormRow>
        </Container>
    );
}

export default NewWorkLine;
