import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    Dialog,
    DialogTitle,
    TextField,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    InputAdornment
} from '@mui/material';

export default function DriverOdometerDialog({ open, onClose, onConfirm }) {
    const { t } = useTranslation();

    const { defaultEquipment } = useSelector((state) => state.employee);
    const [odometer, setOdometer] = useState('');
    const [isValid, setIsValid] = useState(true);

    // Pre-populate latest odometer reading
    useEffect(() => {
        if (defaultEquipment?.odometer != null) {
            setOdometer(defaultEquipment.odometer);
        }
    }, [defaultEquipment?.odometer]);

    const handleOnChange = (e) => {
        const value = e.target.valueAsNumber || '';
        setOdometer(value);
        if (value && value >= defaultEquipment?.odometer) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    const unit = defaultEquipment?.odometerUnit || 'km';

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t(`odometerDialog.title.${unit}`)}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {defaultEquipment?.displayName || "T�ki vantar"}
                </DialogContentText>
                <TextField
                    label={`${t(`odometerDialog.inputLabel.${unit}`)}`}
                    placeholder={t(`odometerDialog.inputPlaceholder.${unit}`)}
                    helperText={`${t(`odometerDialog.lastKnown.${unit}`)} ${defaultEquipment?.odometer || 0
                        } ${t(unit).toLowerCase()}`}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{t(unit).toLowerCase()}</InputAdornment>
                    }}
                    autoFocus
                    disabled={!defaultEquipment?.id}
                    aria-hidden={!open}
                    required
                    margin="normal"
                    id="odometer"
                    name="odometer"
                    type="number"
                    fullWidth
                    value={odometer}
                    onChange={handleOnChange}
                />
                <Typography
                    variant="body2"
                    color={isValid ? 'success.main' : 'error.main'}
                    sx={{ height: '16px' }}
                >
                    {isValid
                        ? t('odometerDialog.looksgood')
                        : odometer && odometer <= defaultEquipment?.odometer
                            ? t('odometerDialog.validation')
                            : ''}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button size="small" fullWidth onClick={onClose} variant="contained">
                    {t('odometerDialog.cancel')}
                </Button>
                <Button
                    disabled={!isValid || !defaultEquipment?.id}
                    size="small"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                        if (isValid || !defaultEquipment?.id) {
                            onConfirm(odometer);
                        } else {
                            toast.error(t('odometerDialog.error'));
                        }
                    }}
                >
                    {t('odometerDialog.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
